import React from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { CreateSmartContractShareFormData } from '../../../../CreateSmartContractShareForm.types';

import { CreateSmartContractShareFormChangeShareUserWithoutGLField } from '../../../CreateSmartContractShareFormChangeShareUserWithoutGLField';
import { CreateSmartContractShareFormChangeShareUserField } from '../../../CreateSmartContractShareFormChangeShareUserField';

import { Translate } from '../../../../../../../../../helpers/Translate';

import { I18nText } from '../../../../../../../../../types';

interface CreateSmartContractShareFormChangeShareUserListProps {
  users: FieldArrayWithId<
    CreateSmartContractShareFormData,
    'users.workers',
    'id'
  >[];
  control: Control<CreateSmartContractShareFormData>;
  setValue: UseFormSetValue<CreateSmartContractShareFormData>;
  getValues: UseFormGetValues<CreateSmartContractShareFormData>;
  disabled?: boolean;
  i18nTitle?: I18nText;
  onRemove?: (index: number) => void;
  withGeneralLedger?: boolean;
  withoutEditUsers?: boolean;
  isClient?: boolean;
}

function CreateSmartContractShareFormChangeShareUserList({
  users,
  control,
  setValue,
  getValues,
  disabled,
  i18nTitle,
  onRemove,
  withGeneralLedger,
  withoutEditUsers,
  isClient
}: CreateSmartContractShareFormChangeShareUserListProps) {
  if (isEmpty(users)) {
    return null;
  }

  return (
    <div>
      <div className="leading-none text-sm sm:text-base mb-2">
        <Translate id={i18nTitle} />
      </div>

      <div className="align-middle inline-block min-w-full relative">
        <div className="overflow-auto">
          <table className="min-w-full relative z-0">
            <tbody>
              {users.map((user, index) =>
                withGeneralLedger ? (
                  <CreateSmartContractShareFormChangeShareUserField
                    key={user.id}
                    companyNanoId={user.currentTeam?.nanoId}
                    index={index}
                    onRemove={
                      withoutEditUsers ? undefined : () => onRemove(index)
                    }
                    disabled={disabled}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    isClient={isClient}
                  />
                ) : (
                  <CreateSmartContractShareFormChangeShareUserWithoutGLField
                    key={user.id}
                    user={user}
                    onRemove={
                      withoutEditUsers ? undefined : () => onRemove(index)
                    }
                    disabled={disabled}
                  />
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CreateSmartContractShareFormChangeShareUserList;
