import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { CreateSmartContractShareFormData } from '../../../../CreateSmartContractShareForm.types';

import { InputField } from '../../../../../../../../../helpers/FormFields/InputField';
import { Translate } from '../../../../../../../../../helpers/Translate';

import { smartContractsKeys } from '../../../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareIterationFieldProps {
  control: Control<CreateSmartContractShareFormData>;
  disabled?: boolean;
}

function CreateSmartContractShareFormChangeShareIterationField({
  control,
  disabled
}: CreateSmartContractShareFormChangeShareIterationFieldProps) {
  return (
    <div className="flex-grow flex flex-col sm:flex-row">
      <div className="block py-2 pr-2 text-sm sm:text-base">
        <Translate id={smartContractsKeys.iteration} />
      </div>

      <div className="w-16 flex gap-2 relative text-sm items-center">
        <Controller
          control={control}
          name="iteration"
          rules={{
            validate: (value) => {
              if (value.length > 0 && +value <= 0) {
                return ' ';
              }

              return true;
            }
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error: fieldError }
          }) => {
            return (
              <InputField
                disabled={disabled}
                error={fieldError?.message}
                i18nPlaceholder={smartContractsKeys.finalIteration}
                inputClassName="border-0 border-b border-gray-300 dark:border-gray-700 p-0 focus:ring-0 focus:border-blue-500 w-full dark:focus:placeholder-gray-500 placeholder-gray-400 focus:placeholder-gray-300 text-sm bg-transparent"
                name={name}
                onChange={onChange}
                value={value?.toString()}
                showErrorIcon={false}
                step="any"
                type="number"
              />
            );
          }}
        />
      </div>
    </div>
  );
}

export default CreateSmartContractShareFormChangeShareIterationField;
