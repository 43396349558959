import {
  CreatedAt,
  DateFilterType,
  DateType,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  ID,
  IDFilter,
  IdFilterType,
  NanoID,
  NanoIdFilterType,
  UUID,
  UpdatedAt
} from '../../types';
import { CreateItemsCacheKeys } from '../../types/createItemsTypes';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import { CompanySmartContractTemplateID } from '../companySmartContractTemplates/companySmartContractTemplatesTypes';
import {
  EventTemplateHandlerTypes,
  EventTemplateID,
  EventTemplateName,
  EventTemplateTriggerType
} from '../eventTemplates/eventTemplatesTypes';
import {
  GeneralLedgerCompanyID,
  GeneralLedgerCompanyName,
  GeneralLedgerCompanyOwnerID,
  GeneralLedgerID
} from '../generalLedgers/generalLedgersTypes';
import {
  SmartContractShareIteration,
  SmartContractShareShare,
  SmartContractShareStatuses
} from '../smartContractShares/smartContractSharesTypes';
import { TeamID } from '../teams/teamsTypes';
import { UserFullName, UserID, UserUUID } from '../users/usersTypes';

export type CompanyEventTemplateID = ID;
export type CompanyEventTemplateUUID = UUID;
export type CompanyEventTemplateNanoID = NanoID;

export type CompanyEventTemplateCreatedAt = CreatedAt;
export type CompanyEventTemplateUpdatedAt = UpdatedAt;
export type CompanyEventTemplateDisabledAt = DateType;
export type CompanyEventTemplateSystemAt = DateType;

export type CompanyEventTemplateName = string;

export type CompanyEventTemplateHandlerArgsCompanySmartContractTemplateID =
  CompanySmartContractTemplateID;

export type CompanyEventTemplateHandlerArgsGeneralLegerID = GeneralLedgerID;
export type CompanyEventTemplateHandlerArgsGeneralLegerCompanyID =
  GeneralLedgerCompanyID;
export type CompanyEventTemplateHandlerArgsGeneralLegerCompanyName =
  GeneralLedgerCompanyName;
export type CompanyEventTemplateHandlerArgsGeneralLegerCompanyOwnerID =
  GeneralLedgerCompanyOwnerID;

export type CompanyEventTemplateHandlerArgsIteration =
  SmartContractShareIteration;
export type CompanyEventTemplateHandlerArgsShare = SmartContractShareShare;
export type CompanyEventTemplateHandlerArgsStatus = SmartContractShareStatuses;

export type CompanyEventTemplateEventTemplateID = EventTemplateID;
export type CompanyEventTemplateEventTemplateName = EventTemplateName;

export type CompanyEventTemplateEventTemplateTriggerType =
  EventTemplateTriggerType;

export type CompanyEventTemplateEventTemplateHandlerType =
  EventTemplateHandlerTypes;

export type CompanyEventTemplateCompanyID = TeamID;

export type CompanyEventTemplateUserID = UserID;
export type CompanyEventTemplateUserUUID = UserUUID;
export type CompanyEventTemplateUserFullName = UserFullName;

export type FetchCompanyEventTemplatesCacheKey = FetchItemsCacheKey;

export type FetchCompanyEventTemplatesSort = FetchItemsSort;
export type FetchCompanyEventTemplatesPage = FetchItemsPage;
export type FetchCompanyEventTemplatesLimit = FetchItemsLimit;

export type FetchCompanyEventTemplatesGqlQuery = FetchItemsGqlQuery;

export type UpdateCompanyEventTemplatesCacheKeys = UpdateItemsCacheKeys;
export type CreateCompanyEventTemplateCacheKeys = CreateItemsCacheKeys;
export type DeleteCompanyEventTemplateCacheKeys = DeleteItemCacheKeys;

export const enum FetchCompanyEventTemplatesSortTypes {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NANO_ID_ASC = 'NANO_ID_ASC',
  NANO_ID_DESC = 'NANO_ID_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  USER_ID_ASC = 'USER_ID_ASC',
  USER_ID_DESC = 'USER_ID_DESC',
  UUID_ASC = 'UUID_ASC',
  UUID_DESC = 'UUID_DESC'
}

export interface FetchCompanyEventTemplatesFilters {
  companyId?: IDFilter;
  withSystemAt?: boolean;
  withoutSystemAt?: boolean;
}

export interface FetchFinCompanyEventTemplatesFilters {
  companyId?: IdFilterType;
  companyNanoId?: NanoIdFilterType;
  createdAt?: DateFilterType;
  disabledAt?: DateFilterType;
}
