import { gql } from 'graphql-request';
import {
  CompanyEventTemplateUUID,
  CompanyEventTemplateID,
  CompanyEventTemplateNanoID,
  CompanyEventTemplateName,
  CompanyEventTemplateCreatedAt,
  CompanyEventTemplateUpdatedAt,
  CompanyEventTemplateEventTemplateID,
  CompanyEventTemplateEventTemplateName,
  CompanyEventTemplateHandlerArgsCompanySmartContractTemplateID,
  CompanyEventTemplateDisabledAt,
  CompanyEventTemplateHandlerArgsGeneralLegerID,
  CompanyEventTemplateSystemAt,
  CompanyEventTemplateHandlerArgsGeneralLegerCompanyID,
  CompanyEventTemplateHandlerArgsGeneralLegerCompanyName,
  CompanyEventTemplateHandlerArgsGeneralLegerCompanyOwnerID
} from '../companyEventTemplatesTypes';

export interface FetchFinCompanyEventTemplateQueryResponse {
  id: CompanyEventTemplateID;
  uuid: CompanyEventTemplateUUID;
  nanoId: CompanyEventTemplateNanoID;
  createdAt: CompanyEventTemplateCreatedAt;
  updatedAt: CompanyEventTemplateUpdatedAt;
  disabledAt: CompanyEventTemplateDisabledAt;
  systemAt: CompanyEventTemplateSystemAt;
  name: CompanyEventTemplateName;
  companySmartContractTemplateId?: CompanyEventTemplateHandlerArgsCompanySmartContractTemplateID;
  handlerGeneralLedger: {
    id: CompanyEventTemplateHandlerArgsGeneralLegerID;
    company: {
      id: CompanyEventTemplateHandlerArgsGeneralLegerCompanyID;
      name: CompanyEventTemplateHandlerArgsGeneralLegerCompanyName;
      ownerId: CompanyEventTemplateHandlerArgsGeneralLegerCompanyOwnerID;
    };
  };
  handlerGeneralLedgerId: CompanyEventTemplateHandlerArgsGeneralLegerID;
  eventTemplate: {
    id: CompanyEventTemplateEventTemplateID;
    name: CompanyEventTemplateEventTemplateName;
  };
}

export const FETCH_FIN_COMPANY_EVENT_TEMPLATES_QUERY = gql`
  query FinCompanyEventTemplates(
    $filters: CompanyEventTemplatesFilters
    $sort: [CompanyEventTemplatesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    companyEventTemplates(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        createdAt
        updatedAt
        disabledAt
        systemAt
        name
        companySmartContractTemplateId
        handlerGeneralLedger {
          id
          company {
            id
            name
            ownerId
          }
        }
        handlerGeneralLedgerId
        eventTemplate {
          id
          name
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
