import React from 'react';

import { CreateSmartContractShareFormDataUser } from '../../CreateSmartContractShareForm.types';

import { UserAvatar } from '../../../../../../common/helpers/UserAvatar';
import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { words } from '../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareUserWithoutGLFieldProps {
  user: CreateSmartContractShareFormDataUser;
  onRemove?: () => void;
  disabled?: boolean;
}

function CreateSmartContractShareFormChangeShareUserWithoutGLField({
  user,
  onRemove,
  disabled
}: CreateSmartContractShareFormChangeShareUserWithoutGLFieldProps) {
  return (
    <tr>
      <td className="w-px text-xs" />

      <td className="w-px px-1 py-0.5 text-xs">
        <UserAvatar user={user} type="xs" />
      </td>

      <td className="py-1 px-2 text-xs w-full">
        <div className="text-gray-900 dark:text-gray-200 whitespace-nowrap font-normal max-w-44 truncate">
          <span>{user?.fullName}</span>
        </div>
      </td>

      {onRemove && (
        <td className="py-0.5 text-xs sticky w-px focus-within:z-5 text-center bg-white bg-opacity-90 dark:bg-gray-850 dark:bg-opacity-90 right-0">
          <PureTooltipIconButtonHelper
            onClick={onRemove}
            tooltipI18nText={words.remove}
            icon={IconsEnum.TRASH_SOLID}
            iconClassName="h-4 w-4"
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 text-gray-400 dark:text-gray-600"
            disabled={disabled}
          />
        </td>
      )}
    </tr>
  );
}

export default CreateSmartContractShareFormChangeShareUserWithoutGLField;
