import React, { Fragment } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useFieldArray,
  useWatch
} from 'react-hook-form';

import { I18nText, IsLoading } from '../../../../../../../types';
import {
  SmartContractShareStatus,
  SmartContractShareStatuses
} from '../../../../../smartContractSharesTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { CreateSmartContractShareFormData } from '../../CreateSmartContractShareForm.types';

import { CreateSmartContractShareFormChangeShareUserList } from './components/CreateSmartContractShareFormChangeShareUserList';
import { CreateSmartContractShareFormChangeShareHeader } from '../CreateSmartContractShareFormChangeShareHeader';
import { CreateSmartContractShareFormChangeShareIterationField } from './components/CreateSmartContractShareFormChangeShareIterationField';
import { CreateSmartContractShareFormChangeShareAppliedEventsField } from './components/CreateSmartContractShareFormChangeShareAppliedEventsField';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { SmartContractSharesPermissions } from '../../../../../smartContractSharesConstants';

import { usersKeys } from '../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareProps {
  control: Control<CreateSmartContractShareFormData>;
  setValue: UseFormSetValue<CreateSmartContractShareFormData>;
  getValues: UseFormGetValues<CreateSmartContractShareFormData>;
  isLoading?: IsLoading;
  goToBack?: () => void;
  i18nTitle?: I18nText;
  withoutEditUsers?: boolean;
  withSelectEvents?: boolean;
  status: SmartContractShareStatus;
}

function CreateSmartContractShareFormChangeShare({
  control,
  setValue,
  getValues,
  isLoading,
  goToBack,
  i18nTitle,
  withoutEditUsers,
  withSelectEvents,
  status
}: CreateSmartContractShareFormChangeShareProps) {
  const currentUser = useCurrentUser();

  const { fields: clients, remove: removeClient } = useFieldArray({
    control,
    name: 'users.clients'
  });

  const { fields: workers, remove: removeWorker } = useFieldArray({
    control,
    name: 'users.workers'
  });

  const inviteClientsToSc = useWatch({
    control,
    name: 'inviteClientsToSc'
  });

  const inviteWorkersToSc = useWatch({
    control,
    name: 'inviteWorkersToSc'
  });

  return (
    <Fragment>
      <CreateSmartContractShareFormChangeShareHeader
        goToBack={goToBack}
        i18nText={i18nTitle}
      />
      <div className="flex-1 px-2">
        <div className="p-4 flex flex-col-reverse space-y-4">
          <CheckPermissions
            action={
              SmartContractSharesPermissions.READ_SMART_CONTRACT_SHARE_CREATE_ITERATION_FIELD
            }
          >
            <CreateSmartContractShareFormChangeShareIterationField
              control={control}
              disabled={
                !currentUser.hasPermissions(
                  SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_ITERATION_FIELD
                ) || isLoading
              }
            />
          </CheckPermissions>

          {withSelectEvents && (
            <CheckPermissions
              action={
                SmartContractSharesPermissions.READ_SMART_CONTRACT_SHARE_CREATE_APPLIED_EVENTS_FIELD
              }
            >
              <CreateSmartContractShareFormChangeShareAppliedEventsField
                control={control}
                disabled={
                  !currentUser.hasPermissions(
                    SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_APPLIED_EVENTS_FIELD
                  ) || isLoading
                }
              />
            </CheckPermissions>
          )}

          <CreateSmartContractShareFormChangeShareUserList
            disabled={isLoading}
            control={control}
            setValue={setValue}
            getValues={getValues}
            withoutEditUsers={withoutEditUsers}
            users={workers}
            i18nTitle={usersKeys.workersPlural}
            onRemove={removeWorker}
            withGeneralLedger={
              inviteWorkersToSc || status !== SmartContractShareStatuses.INVITED
            }
          />

          <CreateSmartContractShareFormChangeShareUserList
            disabled={isLoading}
            control={control}
            setValue={setValue}
            getValues={getValues}
            withoutEditUsers={withoutEditUsers}
            users={clients}
            i18nTitle={usersKeys.clientsPlural}
            onRemove={removeClient}
            isClient
            withGeneralLedger={
              inviteClientsToSc || status !== SmartContractShareStatuses.INVITED
            }
          />
        </div>
      </div>
    </Fragment>
  );
}

export default CreateSmartContractShareFormChangeShare;
