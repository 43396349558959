import {
  FetchCompanyEventTemplatesCacheKey,
  FetchFinCompanyEventTemplatesFilters,
  FetchCompanyEventTemplatesGqlQuery,
  FetchCompanyEventTemplatesLimit,
  FetchCompanyEventTemplatesPage,
  FetchCompanyEventTemplatesSort
} from '../../companyEventTemplatesTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_COMPANY_EVENT_TEMPLATES_FILTERS,
  INITIAL_COMPANY_EVENT_TEMPLATES_LIMIT,
  INITIAL_COMPANY_EVENT_TEMPLATES_PAGE,
  INITIAL_COMPANY_EVENT_TEMPLATES_SORT
} from '../../companyEventTemplatesConstants';

interface PaginatedCompanyEventTemplatesOptions {
  cacheKey: FetchCompanyEventTemplatesCacheKey;
  initialFilters?: FetchFinCompanyEventTemplatesFilters;
  initialSort?: FetchCompanyEventTemplatesSort;
  initialPage?: FetchCompanyEventTemplatesPage;
  initialLimit?: FetchCompanyEventTemplatesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: FetchCompanyEventTemplatesGqlQuery;
}

const scope = 'companyEventTemplates';

function useFinPaginatedCompanyEventTemplates<
  FetchCompanyEventTemplatesCompanyEventTemplateType,
  FetchCompanyEventTemplatesMetaType = undefined
>({
  cacheKey,
  initialFilters = INITIAL_COMPANY_EVENT_TEMPLATES_FILTERS,
  initialSort = INITIAL_COMPANY_EVENT_TEMPLATES_SORT,
  initialPage = INITIAL_COMPANY_EVENT_TEMPLATES_PAGE,
  initialLimit = INITIAL_COMPANY_EVENT_TEMPLATES_LIMIT,
  options,
  query
}: PaginatedCompanyEventTemplatesOptions) {
  const {
    changeItemsFilters,
    clearItemsFilters,
    clearItemsFiltersPersistInitial,
    currentFilters,
    currentLimit,
    currentPage,
    currentSort,
    data,
    filterItems,
    isFetched,
    isLoading,
    isPlaceholderData,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    limitItems,
    meta,
    paginateItems,
    prefetchItems,
    sortItems,
    updateItemCache
  } = useFinIndexQuery<
    FetchCompanyEventTemplatesCompanyEventTemplateType,
    FetchCompanyEventTemplatesMetaType
  >({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    scope,
    query,
    options
  });

  return {
    changeCompanyEventTemplatesFilters: changeItemsFilters,
    clearCompanyEventTemplatesFilters: clearItemsFilters,
    clearCompanyEventTemplatesFiltersPersistInitial:
      clearItemsFiltersPersistInitial,
    filterCompanyEventTemplates: filterItems,
    companyEventTemplates: items,
    companyEventTemplatesData: data,
    companyEventTemplatesError: itemsError,
    companyEventTemplatesErrorMessage: itemsErrorMessage,
    companyEventTemplatesFetched: isFetched,
    companyEventTemplatesFilters: currentFilters,
    companyEventTemplatesIsPlaceholderData: isPlaceholderData,
    companyEventTemplatesLimit: currentLimit,
    companyEventTemplatesLoading: isLoading,
    companyEventTemplatesMeta: meta,
    companyEventTemplatesPage: currentPage,
    companyEventTemplatesSort: currentSort,
    companyEventTemplatesTotalCount: itemsTotalCount,
    limitCompanyEventTemplates: limitItems,
    paginateCompanyEventTemplates: paginateItems,
    prefetchCompanyEventTemplates: prefetchItems,
    sortCompanyEventTemplates: sortItems,
    updateCompanyEventTemplateCache: updateItemCache
  };
}

export default useFinPaginatedCompanyEventTemplates;
