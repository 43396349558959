import React, { useState } from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';

import { FormID, I18nText, IsLoading, UUID } from '../../../../../types';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormView
} from './CreateSmartContractShareForm.types';
import {
  SmartContractShareStatus,
  SmartContractShareStatuses
} from '../../../smartContractSharesTypes';
import { UsersTabType } from '../../../../users/usersTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { CreateSmartContractShareFormChangeShare } from './components/CreateSmartContractShareFormChangeShare';
import { CreateSmartContractShareFormChangeUsers } from './components/CreateSmartContractShareFormChangeUsers';

import { Form } from '../../../../../helpers/Form';
import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { SmartContractSharesPermissions } from '../../../smartContractSharesConstants';

import { smartContractsKeys } from '../../../../../locales/keys';

interface CreateSmartContractShareFormProps {
  invitedUserUuids?: UUID[];
  i18nTitle?: I18nText;
  form: FormID;
  isLoading?: IsLoading;
  control: Control<CreateSmartContractShareFormData>;
  setValue: UseFormSetValue<CreateSmartContractShareFormData>;
  getValues: UseFormGetValues<CreateSmartContractShareFormData>;
  formView: CreateSmartContractShareFormView;
  goToSelectUsersView?: () => void;
  withoutEditUsers?: boolean;
  withSelectEvents?: boolean;
  status: SmartContractShareStatus;
}

function CreateSmartContractShareForm({
  invitedUserUuids,
  control,
  form,
  formView,
  setValue,
  getValues,
  goToSelectUsersView,
  isLoading,
  i18nTitle,
  withoutEditUsers,
  withSelectEvents,
  status
}: CreateSmartContractShareFormProps) {
  const currentUser = useCurrentUser();

  const [openedTab, setOpenedTab] = useState<UsersTabType>('workers');

  return (
    <Form id={form}>
      {formView === CreateSmartContractShareFormView.SELECT_USERS && (
        <div className="flex-1 px-2">
          <div className="px-4">
            <Controller
              control={control}
              name="users"
              render={({ field: { onChange, value } }) => (
                <CreateSmartContractShareFormChangeUsers
                  onChange={onChange}
                  users={value}
                  invitedUserUuids={invitedUserUuids}
                  onTabChange={setOpenedTab}
                />
              )}
            />

            <CheckPermissions
              action={
                SmartContractSharesPermissions.READ_SMART_CONTRACT_SHARE_CREATE_INVITE_TO_SMART_CONTRACT_FIELD
              }
            >
              {status === SmartContractShareStatuses.INVITED && (
                <div className="mt-4 pb-1">
                  <Controller
                    control={control}
                    name="inviteWorkersToSc"
                    render={({ field: { onChange, value, name } }) => (
                      <CheckBoxField
                        className={openedTab === 'clients' && 'hidden'}
                        id={name}
                        name={name}
                        onChange={onChange}
                        checked={value}
                        i18nLabel={smartContractsKeys.invite}
                        disabled={
                          !currentUser.hasPermissions(
                            SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_INVITE_TO_SMART_CONTRACT_FIELD
                          )
                        }
                      />
                    )}
                  />

                  {/* <Controller
                    control={control}
                    name="inviteClientsToSc"
                    render={({ field: { onChange, value, name } }) => (
                      <CheckBoxField
                        className={openedTab === 'workers' && 'hidden'}
                        id={name}
                        name={name}
                        onChange={onChange}
                        checked={value}
                        i18nLabel={smartContractsKeys.invite}
                        disabled={
                          !currentUser.hasPermissions(
                            SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_INVITE_TO_SMART_CONTRACT_FIELD
                          )
                        }
                      />
                    )}
                  /> */}
                </div>
              )}
            </CheckPermissions>
          </div>
        </div>
      )}

      {formView === CreateSmartContractShareFormView.CHANGE_SHARE && (
        <CreateSmartContractShareFormChangeShare
          control={control}
          setValue={setValue}
          getValues={getValues}
          isLoading={isLoading}
          goToBack={goToSelectUsersView}
          i18nTitle={i18nTitle}
          withoutEditUsers={withoutEditUsers}
          withSelectEvents={withSelectEvents}
          status={status}
        />
      )}
    </Form>
  );
}

export default CreateSmartContractShareForm;
