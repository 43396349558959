import React, { useCallback, useMemo, useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import map from 'lodash/map';

import { CompanyNanoID } from '../../../../../../../../companies/companiesTypes';
import { CreateSmartContractShareFormData } from '../../../../CreateSmartContractShareForm.types';

import { CompanyEventTemplatesList } from '../../../../../../../../companyEventTemplates/components/list/CompanyEventTemplatesList';

import { Translate } from '../../../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../../../helpers/Icon';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { words } from '../../../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareAppliedEventsFieldProps {
  control: Control<CreateSmartContractShareFormData>;
  disabled?: boolean;
}

function CreateSmartContractShareFormChangeShareAppliedEventsField({
  control,
  disabled
}: CreateSmartContractShareFormChangeShareAppliedEventsFieldProps) {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpanded = useCallback<() => void>(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  const watchWorker = useWatch({
    control,
    name: 'users.workers'
  });

  const workerCompanyNanoIds = useMemo(
    () => map(watchWorker, (worker) => worker.currentTeam?.nanoId),
    [watchWorker]
  );

  return (
    <div className="space-y-2">
      <div
        className="flex items-center cursor-pointer gap-x-2 text-sm sm:text-base"
        onClick={handleToggleExpanded}
      >
        <div>
          <Translate id={words.appliedEvents} />
        </div>

        <Icon
          className="w-4 h-4"
          icon={expanded ? IconsEnum.CHEVRON_UP : IconsEnum.CHEVRON_DOWN_SOLID}
        />
      </div>

      <div className={expanded ? 'block' : 'hidden'}>
        <Controller
          control={control}
          name="withoutEventIds"
          render={({ field: { onChange, value } }) => {
            return (
              <CompanyEventTemplatesList
                companyNanoIds={workerCompanyNanoIds as CompanyNanoID[]}
                selectedCompanyEventTemplateIds={value}
                onSelectCompanyEventTemplateIds={onChange}
                disabled={disabled}
              />
            );
          }}
        />
      </div>
    </div>
  );
}

export default CreateSmartContractShareFormChangeShareAppliedEventsField;
