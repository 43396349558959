import React, { memo, useCallback, useEffect, useMemo } from 'react';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import map from 'lodash/map';

import { CompanyNanoID } from '../../../../companies/companiesTypes';
import { CompanyEventTemplateID } from '../../../companyEventTemplatesTypes';
import { IsDisabled } from '../../../../../types';

import {
  FETCH_FIN_COMPANY_EVENT_TEMPLATES_QUERY,
  FetchFinCompanyEventTemplateQueryResponse
} from '../../../queries/fetchFinCompanyEventTemplates.query';

import { useFinPaginatedCompanyEventTemplates } from '../../../hooks/useFinPaginatedCompanyEventTemplates';

import { CompanyEventTemplatesListItem } from '../CompanyEventTemplatesListItem';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { NoResults } from '../../../../../helpers/NoResults';

import { CompanyEventTemplateCache } from '../../../CompanyEventTemplateCache';

interface CompanyEventTemplatesListProps {
  companyNanoIds: CompanyNanoID[];
  selectedCompanyEventTemplateIds: CompanyEventTemplateID[];
  onSelectCompanyEventTemplateIds: (ids: CompanyEventTemplateID[]) => void;
  disabled?: IsDisabled;
}

function CompanyEventTemplatesList({
  companyNanoIds,
  selectedCompanyEventTemplateIds,
  onSelectCompanyEventTemplateIds,
  disabled
}: CompanyEventTemplatesListProps) {
  const {
    companyEventTemplates,
    companyEventTemplatesFilters,
    changeCompanyEventTemplatesFilters,
    companyEventTemplatesErrorMessage,
    companyEventTemplatesFetched,
    companyEventTemplatesIsPlaceholderData,
    companyEventTemplatesTotalCount
  } = useFinPaginatedCompanyEventTemplates<FetchFinCompanyEventTemplateQueryResponse>(
    {
      cacheKey: CompanyEventTemplateCache.companiesCacheKey(),
      query: FETCH_FIN_COMPANY_EVENT_TEMPLATES_QUERY,
      initialFilters: {
        companyNanoId: {
          in: companyNanoIds
        },
        disabledAt: {
          isNull: true
        }
      },
      options: {
        withoutPrefetch: true,
        enabled: !isEmpty(companyNanoIds),
        enabledPlaceholder: !isEmpty(companyNanoIds)
      }
    }
  );

  useEffect(() => {
    if (
      !isEqual(companyEventTemplatesFilters.companyNanoId.in, companyNanoIds)
    ) {
      changeCompanyEventTemplatesFilters({
        companyNanoId: {
          in: companyNanoIds
        }
      });
    }
  }, [
    companyNanoIds,
    changeCompanyEventTemplatesFilters,
    companyEventTemplatesFilters.companyNanoId.in
  ]);

  const checkedHash = useMemo(() => {
    return fromPairs(map(selectedCompanyEventTemplateIds, (id) => [id, true]));
  }, [selectedCompanyEventTemplateIds]);

  const handleCheckCompanyTemplate = useCallback<
    (id: CompanyEventTemplateID) => void
  >(
    (id) => {
      onSelectCompanyEventTemplateIds(
        checkedHash[id]
          ? without(selectedCompanyEventTemplateIds, id)
          : [...selectedCompanyEventTemplateIds, id]
      );
    },
    [
      checkedHash,
      onSelectCompanyEventTemplateIds,
      selectedCompanyEventTemplateIds
    ]
  );

  return (
    <div className="pl-2 space-y-1">
      <AlertMessage message={companyEventTemplatesErrorMessage} />
      <LoadingSkeleton
        loaded={
          companyEventTemplatesIsPlaceholderData || companyEventTemplatesFetched
        }
      >
        {companyEventTemplatesTotalCount === 0 ? (
          <NoResults />
        ) : (
          companyEventTemplates.map((companyEventTemplate) => (
            <CompanyEventTemplatesListItem
              key={companyEventTemplate.id}
              companyEventTemplate={companyEventTemplate}
              onCheckCompanyEventTemplate={handleCheckCompanyTemplate}
              disabled={disabled}
              checked={!checkedHash[companyEventTemplate.id]}
            />
          ))
        )}
      </LoadingSkeleton>
    </div>
  );
}

export default memo<CompanyEventTemplatesListProps>(CompanyEventTemplatesList);
