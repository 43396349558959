export const INITIAL_SMART_CONTRACT_SHARES_FILTERS = {};
export const INITIAL_SMART_CONTRACT_SHARES_SORT = [];
export const INITIAL_SMART_CONTRACT_SHARES_PAGE = 1;
export const INITIAL_SMART_CONTRACT_SHARES_LIMIT = 50;

export const FINAL_ITERATION_NUMBER = 999;

export const enum SmartContractSharesPermissions {
  READ_SMART_CONTRACT_SHARE_CREATE_INVITE_TO_SMART_CONTRACT_FIELD = 'read_smart_contract_share_create_invite_to_smart_contract_field',
  CHANGE_SMART_CONTRACT_SHARE_CREATE_INVITE_TO_SMART_CONTRACT_FIELD = 'change_smart_contract_share_create_invite_to_smart_contract_field',

  READ_SMART_CONTRACT_SHARE_CREATE_APPLIED_EVENTS_FIELD = 'read_smart_contract_share_create_applied_events_field',
  CHANGE_SMART_CONTRACT_SHARE_CREATE_APPLIED_EVENTS_FIELD = 'change_smart_contract_share_create_applied_events_field',

  READ_SMART_CONTRACT_SHARE_CREATE_ITERATION_FIELD = 'read_smart_contract_share_create_iteration_field',
  CHANGE_SMART_CONTRACT_SHARE_CREATE_ITERATION_FIELD = 'change_smart_contract_share_create_iteration_field',
  READ_SMART_CONTRACT_SHARE_CREATE_SUBBOOK_FIELD = 'read_smart_contract_share_create_subbook_field',
  CHANGE_SMART_CONTRACT_SHARE_CREATE_SUBBOOK_FIELD = 'change_smart_contract_share_create_subbook_field',
  READ_SMART_CONTRACT_SHARE_CREATE_SHARE_FIELD = 'read_smart_contract_share_create_share_field',
  CHANGE_SMART_CONTRACT_SHARE_CREATE_SHARE_FIELD = 'change_smart_contract_share_create_share_field',

  READ_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON = 'read_check_in_smart_contract_share_button',
  READ_CHECK_IN_WITHOUT_FOLLOWERS_SMART_CONTRACT_SHARE_BUTTON = 'read_check_in_without_followers_smart_contract_share_button',
  READ_USER_CHECK_IN_SMART_CONTRACT_SHARE_BUTTON = 'read_user_check_in_smart_contract_share_button',
  READ_USER_CHECK_IN_WITHOUT_FOLLOWERS_SMART_CONTRACT_SHARE_BUTTON = 'read_user_check_in_without_followers_smart_contract_share_button',

  READ_SMART_CONTRACT_SHARES_PARTICIPANTS_TABLE_ID_FIELD = 'read_smart_contract_shares_participants_table_id_field',
  READ_SMART_CONTRACT_SHARES_PARTICIPANTS_TABLE_USER_ID_FIELD = 'read_smart_contract_shares_participants_table_user_id_field',
  READ_SMART_CONTRACT_SHARES_PARTICIPANTS_TABLE_ROLE_FIELD = 'read_smart_contract_shares_participants_table_role_field',
  READ_SMART_CONTRACT_SHARES_PARTICIPANTS_TABLE_ITERATION_FIELD = 'read_smart_contract_shares_participants_table_iteration_field',
  CHANGE_SMART_CONTRACT_SHARES_PARTICIPANTS_TABLE_ITERATION_FIELD = 'change_smart_contract_shares_participants_table_iteration_field',
  READ_SMART_CONTRACT_SHARES_PARTICIPANTS_TABLE_STATUS_FIELD = 'read_smart_contract_shares_participants_table_status_field',

  READ_SMART_CONTRACT_SHARES_TABLE_ADD_ITERATION_BUTTON = 'read_smart_contract_shares_table_add_iteration_button',
  READ_SMART_CONTRACT_SHARES_TABLE_ADD_SHARE_BUTTON = 'read_smart_contract_shares_table_add_share_button',
  READ_SMART_CONTRACT_SHARES_TABLE_FINAL_ITERATION_ADD_SHARE_BUTTON = 'read_smart_contract_shares_table_final_iteration_add_share_button',
  READ_SMART_CONTRACT_SHARES_TABLE_CHECK_OUT_SHARE_BUTTON = 'read_smart_contract_shares_table_check_out_share_button',
  READ_SMART_CONTRACT_SHARES_TABLE_DELETE_SHARE_BUTTON = 'read_smart_contract_shares_table_delete_share_button',
  READ_SMART_CONTRACT_SHARES_TABLE_AMOUNT_FIELD = 'read_smart_contract_shares_table_amount_field',
  READ_SMART_CONTRACT_SHARES_TABLE_PAID_FIELD = 'read_smart_contract_shares_table_paid_field',
  READ_SMART_CONTRACT_SHARES_TABLE_SHARE_FIELD = 'read_smart_contract_shares_table_share_field',
  READ_SMART_CONTRACT_SHARES_TABLE_SHARE_ID_FIELD = 'read_smart_contract_shares_table_share_id_field',
  READ_SMART_CONTRACT_SHARES_TABLE_USER_ID_FIELD = 'read_smart_contract_shares_table_user_id_field',
  CHANGE_SMART_CONTRACT_SHARES_TABLE_SHARE_FIELD = 'change_smart_contract_shares_table_share_field',

  READ_SMART_CONTRACT_SHARES_WITHOUT_CACHED = 'read_smart_contract_shares_without_cached'
}
