import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { CreateSmartContractShareFormData } from '../../CreateSmartContractShareForm.types';
import {
  GeneralLedgerGeneralLedgerTypes,
  GeneralLedgerID
} from '../../../../../../generalLedgers/generalLedgersTypes';

import {
  FETCH_GENERAL_LEDGERS_QUERY,
  FetchGeneralLedgersQueryResponse
} from '../../../../../../generalLedgers/queries/fetchGeneralLedgers.query';

import { useFinPaginatedGeneralLedgers } from '../../../../../../generalLedgers/hooks/useFinPaginatedGeneralLedgers';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { UserGeneralLedgerSelectField } from './components/UserGeneralLedgerSelectField';
import { UserGeneralLedgerShareField } from './components/UserGeneralLedgerShareField';
import { UserGeneralLedgerCreatedStatusField } from './components/UserGeneralLedgerCreatedStatusField';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TeamAvatarLink } from '../../../../../../teams/helpers/TeamAvatarLink';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { GeneralLedgerCache } from '../../../../../../generalLedgers/GeneralLedgerCache';
import { SmartContractSharesPermissions } from '../../../../../smartContractSharesConstants';

import { words } from '../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareUserFieldProps {
  control: Control<CreateSmartContractShareFormData>;
  setValue: UseFormSetValue<CreateSmartContractShareFormData>;
  getValues: UseFormGetValues<CreateSmartContractShareFormData>;
  companyNanoId: string;
  isClient?: boolean;
  onRemove?: () => void;
  disabled?: boolean;
  index: number;
}

function CreateSmartContractShareFormChangeShareUserField({
  control,
  setValue,
  getValues,
  companyNanoId,
  isClient,
  onRemove,
  disabled,
  index
}: CreateSmartContractShareFormChangeShareUserFieldProps) {
  const currentUser = useCurrentUser();

  const { generalLedgers, generalLedgersError, generalLedgersFetched } =
    useFinPaginatedGeneralLedgers<FetchGeneralLedgersQueryResponse>({
      cacheKey: GeneralLedgerCache.companyGeneralLedgersCacheKey(companyNanoId),
      query: FETCH_GENERAL_LEDGERS_QUERY,
      initialFilters: {
        companyNanoId: {
          eq: companyNanoId
        }
      },
      options: {
        withoutPrefetch: true
      }
    });

  const defaultGeneralLedger = useMemo(
    () =>
      find(
        generalLedgers,
        (generalLedger) =>
          generalLedger.generalLedgerType ===
          GeneralLedgerGeneralLedgerTypes.DEFAULT
      ),
    [generalLedgers]
  );

  const generalLedgerIdValue = useMemo(
    () =>
      getValues(
        isClient
          ? `users.clients.${index}.generalLedgerId`
          : `users.workers.${index}.generalLedgerId`
      ),
    [getValues, index, isClient]
  );

  const setShareValue = useCallback<(share: number) => void>(
    (share) => {
      setValue(
        isClient
          ? `users.clients.${index}.share`
          : `users.workers.${index}.share`,
        share
      );
    },
    [index, isClient, setValue]
  );

  const setShareTypeValue = useCallback<(shareType: string) => void>(
    (shareType) => {
      setValue(
        isClient
          ? `users.clients.${index}.shareType`
          : `users.workers.${index}.shareType`,
        shareType
      );
    },
    [index, isClient, setValue]
  );

  const setGeneralLedgerId = useCallback<(id: GeneralLedgerID) => void>(
    (id) => {
      setValue(
        isClient
          ? `users.clients.${index}.generalLedgerId`
          : `users.workers.${index}.generalLedgerId`,
        id
      );
    },
    [index, isClient, setValue]
  );

  useEffect(() => {
    if (!isEmpty(defaultGeneralLedger) && !generalLedgerIdValue) {
      setShareTypeValue(defaultGeneralLedger.defaultShareType);
      setShareValue(defaultGeneralLedger.defaultShare);
      setGeneralLedgerId(defaultGeneralLedger.id);
    }
  }, [
    defaultGeneralLedger,
    generalLedgerIdValue,
    setGeneralLedgerId,
    setShareTypeValue,
    setShareValue
  ]);

  return (
    <tr>
      <td className="w-px text-xs">
        <AlertMessage message={generalLedgersError} />
        <LoadingSkeleton
          loaded={generalLedgersFetched}
          count={1}
          className="m-0"
        />
      </td>

      {defaultGeneralLedger && (
        <>
          <td className="w-px text-xs">
            <UserGeneralLedgerCreatedStatusField
              control={control}
              index={index}
              isClient={isClient}
            />
          </td>

          <td className="w-px px-1 py-0.5 text-xs">
            <TeamAvatarLink
              team={defaultGeneralLedger.company}
              avatarType="xs"
            />
          </td>

          <td className="py-1 px-2 text-xs w-full">
            <div className="text-gray-900 dark:text-gray-200 whitespace-nowrap font-normal max-w-44 truncate">
              <span>{defaultGeneralLedger.company?.name}</span>
            </div>
          </td>

          <CheckPermissions
            action={
              SmartContractSharesPermissions.READ_SMART_CONTRACT_SHARE_CREATE_SUBBOOK_FIELD
            }
          >
            <td className="px-2 py-0.5 text-xs">
              <UserGeneralLedgerSelectField
                key={index}
                control={control}
                name={
                  isClient
                    ? `users.clients.${index}.generalLedgerId`
                    : `users.workers.${index}.generalLedgerId`
                }
                generalLedgers={generalLedgers}
                defaultGeneralLedgerId={defaultGeneralLedger.id}
                disabled={
                  !currentUser.hasPermissions(
                    SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_SUBBOOK_FIELD
                  ) || disabled
                }
                changeShare={setShareValue}
              />
            </td>
          </CheckPermissions>

          <CheckPermissions
            action={
              SmartContractSharesPermissions.READ_SMART_CONTRACT_SHARE_CREATE_SHARE_FIELD
            }
          >
            <td className="px-2 py-0.5 text-xs">
              <UserGeneralLedgerShareField
                key={index}
                control={control}
                name={
                  isClient
                    ? `users.clients.${index}.share`
                    : `users.workers.${index}.share`
                }
                disabled={
                  !currentUser.hasPermissions(
                    SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_SHARE_FIELD
                  ) || disabled
                }
              />
            </td>
          </CheckPermissions>

          {onRemove && (
            <td className="py-0.5 text-xs sticky w-px focus-within:z-5 text-center bg-white bg-opacity-90 dark:bg-gray-850 dark:bg-opacity-90 right-0">
              <PureTooltipIconButtonHelper
                onClick={onRemove}
                tooltipI18nText={words.remove}
                icon={IconsEnum.TRASH_SOLID}
                iconClassName="h-4 w-4"
                className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 text-gray-400 dark:text-gray-600"
                disabled={disabled}
              />
            </td>
          )}
        </>
      )}
    </tr>
  );
}

export default CreateSmartContractShareFormChangeShareUserField;
