import React, { memo, useCallback } from 'react';

import { IsDisabled } from '../../../../../types';

import {
  CompanyEventTemplateEventTemplateName,
  CompanyEventTemplateHandlerArgsGeneralLegerCompanyName,
  CompanyEventTemplateID
} from '../../../companyEventTemplatesTypes';

import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';

interface CompanyEventTemplatesListItemType {
  id: CompanyEventTemplateID;
  handlerGeneralLedger: {
    company: {
      name: CompanyEventTemplateHandlerArgsGeneralLegerCompanyName;
    };
  };
  eventTemplate: {
    name: CompanyEventTemplateEventTemplateName;
  };
}

interface CompanyEventTemplatesListItemProps {
  companyEventTemplate: CompanyEventTemplatesListItemType;
  onCheckCompanyEventTemplate: (id: CompanyEventTemplateID) => void;
  disabled?: IsDisabled;
  checked?: boolean;
}

function CompanyEventTemplatesListItem({
  companyEventTemplate,
  onCheckCompanyEventTemplate,
  disabled,
  checked
}: CompanyEventTemplatesListItemProps) {
  const handleSelectCompanyEventTemplateIds = useCallback<() => void>(() => {
    onCheckCompanyEventTemplate(companyEventTemplate.id);
  }, [companyEventTemplate.id, onCheckCompanyEventTemplate]);

  return (
    <div key={companyEventTemplate.id} className="flex gap-2">
      <CheckBoxField
        id={companyEventTemplate.id}
        name={companyEventTemplate.id}
        checked={checked}
        onChange={handleSelectCompanyEventTemplateIds}
        label={companyEventTemplate.eventTemplate?.name}
        disabled={disabled}
      />
      <span className="font-medium text-sm">
        {companyEventTemplate.handlerGeneralLedger?.company?.name}
      </span>
    </div>
  );
}

export default memo<CompanyEventTemplatesListItemProps>(
  CompanyEventTemplatesListItem
);
